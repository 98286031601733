import { useContext, useState } from "react";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import DrawerSG from "../../../../components/design/Drawer/DrawerSG";
import { Card, Col, Divider, Row, Tabs, Tag, Timeline } from "antd";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import { SpinSG } from "../../../../components/model/Spin/SpinSG";
import TabPane from "antd/lib/tabs/TabPane";
import ArticleLogs from "./ArticleLogs";
import ArticleCarousel from "./ArticleCarousel";
import ButtonSG from "../../../../components/design/Buttons/ButtonSG";
import { ROLE_ADMIN, ROLE_APP_BACKOFFICE } from "../../../../../common/constants/roles";
import { ArticlesToSellModal } from "../../../logistics/inventory/modal/ArticlesToSellModal";
import { Owners } from "../../../../../common/constants/Owners";
import { EntityLabel } from "../../../../components/design/EntityLabel/EntityLabel";
import { customRequest } from "../../../../../common/request/customRequest";
import { GlobalVariableContext } from "../../../../../store/GlobalVariableContext/GlobalVariableContext";
import { SelectEditable } from "../../../../components/design/SelectEditable/SelectEditable";
import { ArticleAssignContractModal } from "../../../logistics/inventory/modal/ArticleAssignContractModal";
import { InputEditable } from "../../../../components/design/InputEditable/InputEditable";
import { DatePickerEditable } from "../../../../components/design/DatePickerEditable/DatePickerEditable";
import { defaultFormat } from "../../../../../common/utils/formatDate";
import { useIsAuthorized } from "../../../../../common/request/AuthorizationUtils";


const ArticleDrawer = () => {
    
    const IsAuthorized = useIsAuthorized();

    const { articleDrawer, setArticleDrawer, setProductDrawer, setContractDrawer, setInvestmentDrawer, setClientDrawer } = useContext(DrawerContext)
    const [updateData, setUpdateData] = useState(false);


    const { updateUserData, setUpdateUserData } = useContext(GlobalVariableContext);
    

    const [drawerChanges, setDrawerChanges] = useState(false);

    const [productToSell, setProductToSell] = useState(null);
    const [articleToAssign, setArticleToAssign] = useState(null);


    const { data: article, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/products/articles/${articleDrawer}`,
        {},
        [updateData],
    );

    const [updateCost, setUpdateCost] = useState(false);



    const onFormFinish = async (values: any) => {
        const body = {
            mask: "STORE",
            store: values.store,
        }

        await customRequest(`${process.env.REACT_APP_API}/products/articles/${articleDrawer}`, { method: 'PATCH', data: body }, 'Cambios guardados').then(()=>{
            setUpdateData(!updateData)
        })
    }

    const onFormCost = async (values: any) => {
        const body = {
            mask: "COST",
            cost: values.cost,
        }

        await customRequest(`${process.env.REACT_APP_API}/products/articles/${articleDrawer}`, { method: 'PATCH', data: body }, 'Cambios guardados').then(()=>{
            setUpdateData(!updateData)
            setUpdateCost(!updateCost)
        })
    }

    const onFormBarcode = async (values: any) => {
        const body = {
            mask: "BARCODE",
            barCode: values.barCode,
        }

        await customRequest(`${process.env.REACT_APP_API}/products/articles/${articleDrawer}`, { method: 'PATCH', data: body }, 'Cambios guardados').then(()=>{
            setUpdateData(!updateData)
        })
    }

    const onFormCreated = async (values: any) => {
        const body = {
            mask: "CREATED",
            created: values.created.format("YYYY-MM-DDTHH:mm")
        }

        await customRequest(`${process.env.REACT_APP_API}/products/articles/${articleDrawer}`, { method: 'PATCH', data: body }, 'Cambios guardados').then(()=>{
            setUpdateData(!updateData)
        })
    }


	const { data: stores } = useFetch(`${process.env.REACT_APP_API}/combos/stores`, {});

    return (
        <>
            {articleDrawer && (
                <DrawerSG
                    title={<EntityLabel prefix="Artículo" id={article.id} color={false} />}
                    onClose={() => {
                        setArticleDrawer(null)
                        if (drawerChanges) {
                            setUpdateUserData(!updateUserData);
                        }
                        setDrawerChanges(false); 
                    }}
                    placement='right'
                    visible={!!articleDrawer}>

                    {isLoading ? <SpinSG /> :
                        <div style={{ padding: '10px 30px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            <Card style={{ borderRadius: '25px' }}
                                title={''}
                                size="small">
                                {article.onSale && <Tag color='purple' style={{borderRadius:'5px'}}><b>Artículo en Venta</b></Tag>}
                                 <Row gutter={[16, 16]}>
                                    <Col>
                                        <div>
                                        <span className="label-item-view">Producto</span>
                                            <div className="item-view-product">{article.productType?.name}</div>
                                            <small className="item-view-product"><a  onClick={() => setProductDrawer(article.productType?.sku)}>{article.productType?.sku}</a></small>
                                        </div>
                                    </Col>                                    
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                   
                                        <SelectEditable label='Almacén' nameInput='store' onFormFinish={onFormFinish} value={article.store} comboData={stores}/>
                                    </Col>
                                    <Col span={12}>
                                        <div>
                                        <span className="label-item-view">Propietario</span>
                                            <div className="item-view-product">{article.ownerDescription}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    {article.contractId && <Col span={12}>
                                        <div>
                                        <span className="label-item-view">Contrato</span>
                                            <div className="item-view-product">{<a onClick={() => setContractDrawer(article.contractId)}><EntityLabel prefix="Contrato" id={article.contractId}/></a>}</div>
                                        </div>
                                    </Col>}
                                    {article.investmentId &&<Col span={12}>
                                        <div>
                                        <span className="label-item-view">Inversión</span>
                                            <div className="item-view-product">{article.investmentId ? <a  onClick={() => setInvestmentDrawer(article.investmentId)}><EntityLabel color={false} id={article.investmentId}/></a> : "-"}</div>
                                        </div>
                                    </Col>}
                                </Row>
                                {article.clientId &&

                                    <Row gutter={[16, 16]}>
                                        <><Col span={12}>
                                            <div>
                                                {article.owner === 'CLIENT' ?  <span className="label-item-view">Cliente eCommerce</span> : <span className="label-item-view">Cliente</span>}
                                                <div className="item-view-product">{<a onClick={() => setClientDrawer(article.clientId)}><EntityLabel prefix="Cliente" id={article.clientId} /></a>}</div>
                                            </div>
                                        </Col>
                                            <Col span={12}>
                                                <div>
                                                    <span className="label-item-view">Nombre</span>
                                                    <div className="item-view-product">{article.clientName}</div>
                                                </div>
                                            </Col></>
                                    </Row>
                                }
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <InputEditable symbol='€' value={article.cost} label='Coste' nameInput='cost' onFormFinish={onFormCost}/>
                                    </Col>
                                    <Col span={12}>
                                        <InputEditable value={article.barCode || "-"} label='Código de barras' nameInput='barCode' onFormFinish={onFormBarcode}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DatePickerEditable value={defaultFormat(article.created)} label='Fecha de compra' nameInput='created' onFormFinish={onFormCreated}/>
                                    </Col>
                                </Row>
                            </Card>
                            <Divider />
                            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                <ButtonSG
                                    size='small'
                                    disabled={!IsAuthorized([ROLE_ADMIN, ROLE_APP_BACKOFFICE.WRITE])}
                                    onClick={() => setArticleToAssign(article)}
                                >
                                    Venta a Contrato
                                </ButtonSG>

                                {article.owner !== Owners.CLIENT &&

                                    <ButtonSG
                                        size='small'
                                        disabled={!IsAuthorized([ROLE_ADMIN, ROLE_APP_BACKOFFICE.WRITE])}
                                        onClick={() => setProductToSell(article)}
                                    >
                                        Venta Interna
                                    </ButtonSG>}
                            </div>


                            <Tabs type="card">
                                <TabPane tab="Registro de Cambios" key="historico">
                                    <ArticleLogs articleDrawer={articleDrawer} updateCost={updateCost} />
                                </TabPane>
                                <TabPane tab="Imágenes" key="images">
                                    <ArticleCarousel articleDrawer={articleDrawer} />
                                </TabPane>
                            </Tabs>
                        </div>
                    }
                </DrawerSG>
            )}
            {
                productToSell && (
                    <ArticlesToSellModal
                        product={productToSell}
                        onFinish={() => {
                            setProductToSell(null);
                            setUpdateData(!updateData);
                        }} />
                )
            }

            {
                articleToAssign && (
                    <ArticleAssignContractModal
                        product={articleToAssign}
                        onFinish={() => {
                            setArticleToAssign(null);
                            setUpdateData(!updateData);
                        }} />
                )
            }
        </>
    )
}

export default ArticleDrawer;