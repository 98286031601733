import { useContext, useEffect, useState } from "react";
import ButtonSG from "../../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../../components/design/Table/TableSG";
import { BuyInternalStockModal } from "../../../ecommerce/products/modal/BuyInternalStockModal";
import { MassiveAdjudicationModal } from "../../../ecommerce/products/modal/MassiveAdjudicationModal";
import { InternalStock } from "../../../../../interfaces/InternalStock";
import { InternalArticle } from "../../../../../interfaces/InternalArticle";
import { Tag, Input, Badge, Card } from "antd";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import "./AdjudicateDetail.css"
import { authRequest } from "../../../../../common/request/authRequest";
import { FilterDropdownProps } from "../../../../../types/filterDropDown";
import { CaretRightOutlined, CheckOutlined, DoubleRightOutlined, SearchOutlined, ShopOutlined, ShoppingCartOutlined, SwapOutlined } from '@ant-design/icons';

import { ADJUDICATION_STATUS, SALE_STATUS } from "../../../../../common/constants/adjudicationStatus";
import { formatPrice } from "../../../../../common/utils/formatPrice";
import { SaleArticleIntermediationModal } from "../../../ecommerce/products/modal/SaleArticleIntermediationModal";
import { EntityLabel } from "../../../../components/design/EntityLabel/EntityLabel";

export const CardMetalSales = ({ articleWithStatus, onChange }: any) => {
    
    const { setArticleDrawer, setProductDrawer, setContractDrawer } = useContext(DrawerContext)
    const [selectedArticle, setSelectedArticle] = useState<any>(null);    

    const columns: any = [
        {
            title: 'Producto',
            key: 'productName',
            dataIndex: 'productName',
            render: (_: any, record: any) => {
                return <a onClick={() => setProductDrawer(record.sku)}>
                    <div>{record.productName}</div>
                    <small>{record.sku}</small>
                </a>
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Nombre"
                        value={selectedKeys[0]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: string, record: any) => {
                return (record?.productName.toLowerCase().includes(value.toLowerCase())
                    || (record?.sku.toLowerCase().includes(value.toLowerCase())));
            },
        },
        {
            title: 'Precio',
            key: 'price',
            dataIndex: 'price',
            align: 'center',
            render: (text: string, record: any) => (
                <Tag color="var(--white)" className='market_price'>{formatPrice(record.price, 2)} €</Tag>
            ),
            sorter: (a: any, b: any) => b.price - a.price

        },            
        {
            title: 'Comisión',
            key: 'margin',
            dataIndex: 'margin',
            align: 'center',
            render: (text: string, record: any) => (
                <>{record.margin > 0 && <>{formatPrice(100 * record.margin, 1)} %</>}</>
            )

        },
        {
            title: 'Comprador',
            key: 'buyInvestment',
            dataIndex: 'buyInvestment',
            //align: 'center',
            render: (text: string, record: any) => (
                <>{record.status === "INTERMEDIATION" && <Card style={{ padding: '5px 0px 0px 0px', backgroundColor: 'white', borderRadius: '10px' }}>
                    <a onClick={() => setContractDrawer(record.buyInvestment.contractId)}>
                        <EntityLabel style={{ display: 'inline' }} prefix='Contrato' id={record.buyInvestment?.contractId} />
                    </a>
                    <small>{record.buyInvestment?.clientName}</small>
                </Card>
            }</>
            )
        },
        {
            title: 'Artículo',
            key: 'barCode',
            dataIndex: 'barCode',
            align: 'center',
            render: (text: string, record: any) => (
                <>

                    <a onClick={() => setArticleDrawer(record.id)}>
                        <strong>{record.barCode}</strong>
                    </a>
                </>
            ),
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Código de barras"
                        value={selectedKeys[0]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: string, record: any) => {
                return (record?.article?.barCode?.toLowerCase().includes(value.toLowerCase()));
            },
        },
        {
            title: 'Estado',
            key: 'status',
            align: 'center',
            render: (text: string, record: any) => (
                <>
                    {
                        record?.status && <Tag color={SALE_STATUS[record?.status].color}>{SALE_STATUS[record?.status].name}</Tag>
                    }
                </>
            )
        },
        {
            title: '',
            key: 'action',
            align: 'center',

            render: (text: string, record: any) => (
                <>                                                            
                    { 
                        record?.status !== "SOLD" && <ButtonSG size="small" key="submit" type="primary" color="yellow" onClick={() => { setSelectedArticle(record) }}>
                            <DoubleRightOutlined/> Vender
                        </ButtonSG>                    
                    }
                </>
            )
        }        
    ]

    return (
        <div>
            <Card style={{ border: 'none' }}>
                <div style={{ padding: '30px 10px' }}>
                    <TableSG
                        columns={columns}
                        dataSource={articleWithStatus}
                        size="middle"
                        loading={false}
                        rowKey="article.id"
                    />
                </div>
            </Card>

            {                
                selectedArticle &&
                <SaleArticleIntermediationModal article={selectedArticle} onCancel={() => { setSelectedArticle(null); }} onFinish={() => { setSelectedArticle(null); onChange(); }} />
            }
        </div>
    )
}
