import { Card, Col, Form, Input, InputNumber, Modal, Row, Select, Tag } from 'antd'
import '../../../../../styles/modalSG.sass'
import ButtonSG from '../../../../components/design/Buttons/ButtonSG'
import { InternalArticle } from '../../../../../interfaces/InternalArticle'
import { customRequest } from '../../../../../common/request/customRequest'
import { useState } from 'react'
import { STATUS_CODE } from '../../../../../common/constants/statusCode'
import { InputPrice } from '../../../../components/design/InputPrice/InputPrice'
import { formatPrice } from '../../../../../common/utils/formatPrice'
import { EntityLabel } from '../../../../components/design/EntityLabel/EntityLabel'
import { SALE_STATUS } from '../../../../../common/constants/adjudicationStatus'
import { useFetch } from '../../../../../hooks/useFetch/useFetch'

export const SaleArticleIntermediationModal = (props : {
	article: any,
	onCancel : any,
	onFinish : any
}) => {
	
	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState(null);
	const isIntermedition = props.article.status === "INTERMEDIATION";
	const { data: owners } = useFetch(`${process.env.REACT_APP_API}/combos/internalOwners`, {});

	const onFormFinish = async (values: any) => {

		const data = isIntermedition ? 
		{
			buyerOwner: "CONTRACT",
			articleId: props.article.id,
			sellerInvestmentId: props.article.sellerInvestment.id,			
			buyerClientId: props.article.buyInvestment.clientId,
			buyerContractId: props.article.buyInvestment.contractId,
			buyerInvestmentId: props.article.buyInvestment.id,	
			price: values.price,
		}
		:
		{
			articleId: props.article.id,			
			sellerInvestmentId: props.article.sellerInvestment.id,			
			buyerOwner: values.buyerOwner,	
			price: values.price,
		}

		const response = await customRequest(`${process.env.REACT_APP_API}/transactions`, { method: 'POST', data: data })

		if (response.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			props.onFinish();
		}				
	};

	return (
		
		<Modal 
			open={true} 
			onOk={() => form.submit()} 
			onCancel={props.onCancel} 
			afterClose={form.resetFields}		
			footer={[
				<div style={{padding:'10px'}}>
					<ButtonSG key="back" color='dark-white' onClick={props.onCancel}>
						Cancelar 
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
						Vender 
					</ButtonSG>
				</div>
			]}>
				<h2 style={{marginTop: '10px'}}>Sugerencia de venta</h2>
				
				<Card style={{ padding: '5px 0px 0px 0px', backgroundColor: 'white', borderRadius: '10px' }}>                    
					{props.article?.status && <Tag color={SALE_STATUS[props.article?.status].color}>{SALE_STATUS[props.article?.status].name}</Tag>}
					<b>Comisión:</b> {formatPrice(100 * props.article?.margin, 1)} %
					<br/>
					{props.article?.barCode && <div><b>Código de Barras: </b>{props.article?.barCode}</div>}					
                </Card>
				<br/>	
				{isIntermedition && 
					<div>
						<b>Comprador Sugerido:</b>
						<EntityLabel style={{ display: 'inline' }} prefix='Contrato' id={props.article?.buyInvestment?.contractId} />                    
						<small>{props.article?.buyInvestment?.clientName}</small>
					</div>
				}
				<br/>	
				<Form layout='vertical' form={form} onFinish={onFormFinish} initialValues={{price: formatPrice(props.article?.price * (1 + props.article?.margin), 2) }} >										
					{!isIntermedition && <Form.Item label='Propietario nuevo' name='buyerOwner' rules={[{ required: true }]}>
						<Select defaultValue="">
							{
								owners && owners.map((element: any) =>
									<Select.Option value={element.key}>{element.description}</Select.Option>
								)}
						</Select>
					</Form.Item>}
					
					<InputPrice label={'Precio (con IVA incluido)'} nameInput='price' disabled={false}/>
				</Form>				
		</Modal>
	)	
}

