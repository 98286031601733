
import { RouterType } from '../types/routing/TypeRouter';
import { AdminLayout } from '../view/layouts/sideMenuLayout/AdminLayout';
import { ClientLayout } from '../view/layouts/sideMenuLayout/ClientLayout';
import { EcommerceLayout } from '../view/layouts/sideMenuLayout/EcommerceLayout';
import { LogisticaLayout } from '../view/layouts/sideMenuLayout/LogisticaLayout';
import Users from '../view/pages/users/users/Users';
import Error403 from '../view/pages/common/errors/Error403';
import Error404 from '../view/pages/common/errors/Error404';
import Error500 from '../view/pages/common/errors/Error500';
import Login from '../view/pages/login/Login';
import RecoverPassword from '../view/pages/login/RecoverPassword';
import Welcome from '../view/pages/register/Welcome';
import { Home } from '../view/pages/home/Home';
import { Default } from "../view/pages/home/Default";
import Register from '../view/pages/register/Register';
import { OperationsLayout } from '../view/layouts/sideMenuLayout/OperationsLayout';
import { Providers } from '../view/pages/logistics/providers/Provider';
import { Stores } from '../view/pages/logistics/store/Stores';
import { WebShops } from '../view/pages/ecommerce/webs/WebShops';
import { OrganigramaDepartmet } from '../view/pages/users/departments/organigramas/OrganigramaDepartmet';
import { UsersSummary } from '../view/pages/users/summary/UsersSummary';
import { Wallet } from '../view/pages/operations/wallet/Wallet';
import { LogisticsSummary } from '../view/pages/logistics/summary/LogisticsSummary';
import { OperationsSummary } from '../view/pages/operations/summary/OperationsSummary';
import { EcommerceSummary } from '../view/pages/ecommerce/summary/EcommerceSummary';
import { ProductTypes } from '../view/pages/logistics/productType/ProductTypes';
import { ShoppingLayout } from '../view/layouts/sideMenuLayout/ShoppingLayout';
import { LogisticRequests } from '../view/pages/logistics/request/LogisticRequests';
import { Articles } from '../view/pages/logistics/inventory/Articles';
import { Prospects } from '../view/pages/comercial/prospect/Prospects';
import { CommercialSummary } from '../view/pages/comercial/summary/CommercialSummary';
import { Contracts } from '../view/pages/comercial/contracts/Contracts';
import { WordPressPrices } from '../view/pages/ecommerce/prices/WordPressPrices';
import { PetitionsSummary } from '../view/pages/purchases/summary/PetitionsSummary';
import { Quotation } from '../view/pages/logistics/quotation/Quotation';
import { Opportunities } from '../view/pages/operations/opportunities/Opportunities';
import { OnSale } from '../view/pages/logistics/onSale/OnSale';
import { TechnologyLayout } from '../view/layouts/sideMenuLayout/TechnologyLayout';
import { AccountingLayout } from '../view/layouts/sideMenuLayout/AccountingLayout';
import { AccountingSummary } from '../view/pages/accounting/summary/AccountingSummary';
import { Transactions } from '../view/pages/accounting/transactions/Transactions';
import { Payments } from '../view/pages/accounting/payments/Payments';
import { WordPressOrders } from '../view/pages/ecommerce/orders/WordpressOrders';
import { WordPressClients } from '../view/pages/ecommerce/clients/WordPressClients';
import { Petitions } from '../view/pages/purchases/petitions/Petitions';
import { Orders } from '../view/pages/purchases/orders/Orders';
import { Labeled } from '../view/pages/logistics/labeled/Labeled';
import { SellAssigned } from '../view/pages/operations/onsale/SellAssigned';
import { Tipos } from '../view/pages/operations/contractType/Tipos';
import { SyncProducts } from '../view/pages/ecommerce/products/SyncProducts';
import { BusinessParams } from '../view/pages/operations/contractType/BusinessParams';
import { Notifications } from '../view/pages/notifications/Notifications';
import ClientApp from '../view/pages/users/clientApp/AppClient';
import { UserActivity } from '../view/pages/technology/actividad/UserActivity';
import { Technology } from '../view/pages/technology/services/Technology';
import { AppLayout } from '../view/layouts/sideMenuLayout/AppLayout';
import { AppProducts } from '../view/pages/app/AppProducts';
import { AppRequest } from '../view/pages/app/AppRequest';
import { TotalFreedom } from '../view/pages/operations/totalFreedom/TotalFreedom';
import { FreedomContractDetail } from '../view/pages/operations/totalFreedom/FreedomContractDetail';
import { ConsultantsComission } from '../view/pages/accounting/commissions/ConsultantsCommission';
import { Marketplace } from '../view/pages/logistics/adjudicate/Marketplace';
import { PurchaseForPayment } from '../view/pages/operations/purchaseForPayment/PurchaseForPayment';
import { AdjudicateSale } from '../view/pages/logistics/adjudicate/detail/AdjudicateSale';
import { Clients } from '../view/pages/comercial/clients/Clients';
import { AdjudicateBuy } from '../view/pages/logistics/adjudicate/detail/AdjudicateBuy';

export const clientMenuRoutes: Array<RouterType> = [
	{
		path: '/client/inicio',
		component: CommercialSummary,
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
	},
	{
		path: '/client/prospectos',
		component: Prospects
	},
	{
		path: '/client/elegible',
		component: Clients
	},
	{
		path: '/client/contratos',
		component: Contracts
	},
	{
		path: '/client/error403',
		component: Error403,
	},
	{
		path: '/client/error404',
		component: Error404,
		
	},
	{
		path: '/client/error500',
		component: Error500,
	
	}
]

export const contratosMenuRoutes: Array<RouterType> = [
	{
		path: '/contratos/inicio',
		component: OperationsSummary,
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
	},
	{
		path: '/contratos/sgp',
		component: Wallet
	},
	{
		path: '/contratos/tipos',
		component: Tipos
	},
	{
		path: '/contratos/oportunidades',
		component: Opportunities
	},
	{
		path: '/contratos/ventas',
		component: SellAssigned
	},
	{
		path: '/contratos/negocio',
		component: BusinessParams
	},
	{
		path: '/contratos/libertad',
		component: TotalFreedom
	},
	{
		path: '/contratos/compraUnica',
		component: PurchaseForPayment
	},
	// {
	// 	path: '/contratos/compraPeriodica',
	// 	component: PeriodicPurchase
	// },
	{
		path:'/contratos/libertadDetail/*',
		component: FreedomContractDetail
	},
	{
		path: '/contratos/error403',
		component: Error403,
	},
	{
		path: '/contratos/error404',
		component: Error404,
		
	},
	{
		path: '/contratos/error500',
		component: Error500,
	}

]

export const eCommerceMenuRoutes: Array<RouterType> = [
	{
		path: '/ecommerce/inicio',
		component: EcommerceSummary,
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
	},
	{
		path: '/ecommerce/sync',
		component: SyncProducts
	},
	{
		path: '/ecommerce/pedidos',
		component: WordPressOrders
	},
	{
		path: '/ecommerce/clientes',
		component: WordPressClients
	},
	{
		path: '/ecommerce/precios',
		component: WordPressPrices 
	},
	{
		path: '/ecommerce/webs',
		component: WebShops 
	},
	{
		path: '/ecommerce/error403',
		component: Error403,
	},
	{
		path: '/ecommerce/error404',
		component: Error404,
		
	},
	{
		path: '/ecommerce/error500',
		component: Error500,
	}		
]

export const logisticaMenuRoutes: Array<RouterType> = [
	{
		path: '/logistica/inicio',
		component: LogisticsSummary,
	},
	{
		path: '/logistica/productos',
		component: ProductTypes,
	},
	{
		path: '/logistica/inventario',
		component: Articles,
	},
	{
		path: '/logistica/venta',
		component: OnSale,
	},
	{
		path: '/logistica/adjudicar',
		component: Marketplace,
	},
	{
		path: '/logistica/etiquetado',
		component: Labeled,
	},
	{
		path: '/logistica/adjudicarcompra/*',
		component: AdjudicateBuy,
	},
	{
		path: '/logistica/adjudicarventa/*',
		component: AdjudicateSale,
	},
	{
		path: '/logistica/pedidos',
		component: LogisticRequests,
	},
	{
		path: '/logistica/almacenes',
		component: Stores
	},
	{
		path: '/logistica/cotizaciones',
		component: Quotation
	},
	{
		path: '/logistica/error403',
		component: Error403,
	},
	{
		path: '/logistica/error404',
		component: Error404,
		
	},
	{
		path: '/logistica/error500',
		component: Error500,
	}	
		
]

export const shoppingMenuRoutes: Array<RouterType> = [
	{
		path: '/compras/inicio',
		component: PetitionsSummary,	
	},
	{
		path: '/compras/pendientes',
		component: Petitions
	},
	{
		path: '/compras/ordenes',
		component: Orders
	},
	{
		path: '/compras/proveedores',
		component: Providers,
	},
	{
		path: '/compras/error403',
		component: Error403,
	},
	{
		path: '/compras/error404',
		component: Error404,
		
	},
	{
		path: '/compras/error500',
		component: Error500,
	}	
]


export const accountingMenuRoutes: Array<RouterType> = [
	{
		path: '/contabilidad/inicio',
		component: AccountingSummary,	
	},
	{
		path: '/contabilidad/transacciones',
		component: Transactions,
	},
	{
		path: '/contabilidad/pagos',
		component: Payments,
	},
	{
		path: '/contabilidad/comisiones',
		component: ConsultantsComission,
	},
	{
		path: '/contabilidad/error403',
		component: Error403,
	},
	{
		path: '/contabilidad/error404',
		component: Error404,
		
	},
	{
		path: '/contabilidad/error500',
		component: Error500,
	}	
]

export const adminMenuRoutes: Array<RouterType> = [
	{
		path: '/admin/inicio',		
		component: UsersSummary,
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
	},
	{
		path: '/admin/usuarios',	
		component: Users,
		viewHeader: false,	
		isAuthenticated: true,
		exact: true,
	},
	{
		path: '/admin/clientes',	
		component: ClientApp,
		viewHeader: false,	
		isAuthenticated: true,
		exact: true,
	},
	{
		path: '/admin/departments',	
		component: Default,
		viewHeader: false,	
		isAuthenticated: true,
		exact: true,
	},
	{
		path: '/admin/department/*',	
		component: OrganigramaDepartmet,
		viewHeader: false,	
		isAuthenticated: true,
		exact: true,
	},
	{
		path: '/admin/error403',
		component: Error403,
	},
	{
		path: '/admin/error404',
		component: Error404,
		
	},
	{
		path: '/admin/error500',
		component: Error500,
	}		
]

export const appMenuRoutes:  Array<RouterType> = [
	{
		path: '/app/inicio',
		component: AppProducts,	
	},
	{
		path: '/app/solicitudes',
		component: AppRequest,	
	},
	
]

export const technologyMenuRoutes: Array<RouterType> = [
	{
		path: '/tecnologia/inicio',
		component: Technology,	
	},
	{
		path: '/tecnologia/actividad',
		component: UserActivity,
	},
	{
		path: '/tecnologia/error403',
		component: Error403,
	},
	{
		path: '/tecnologia/error404',
		component: Error404,
		
	},
	{
		path: '/tecnologia/error500',
		component: Error500,
	}	
]

export const routes: Array<RouterType> = [	
	{
		path: '/home',
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
		component: Home,
	},
	{
		path: '/welcome',
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
		component: Welcome,
	},
	{
		path: '/',
		component: Home,
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
	},	
	{
		path: '/login',
		component: Login,
		viewHeader: false,
		isAuthenticated: false,
		exact: true
	},
	{
		path: '/client',
		component: ClientLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/contratos',
		component: OperationsLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/compras',
		component: ShoppingLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/ecommerce',
		component: EcommerceLayout,
		viewHeader: true,		
		isAuthenticated: true,
	},
	{
		path: '/logistica',
		component: LogisticaLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},  	
	{
		path: '/admin',
		component: AdminLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/tecnologia',
		component: TechnologyLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/app',
		component: AppLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/contabilidad',
		component: AccountingLayout,
		viewHeader: true,	
		isAuthenticated: true,	
	},
	{
		path: '/notificaciones',
		viewHeader: true,	
		isAuthenticated: true,
		exact: true,
		component: Notifications,
	},
	{
		path: '/public/register/*',
		component: Register,
		// component: UserStep1Register,
		viewHeader: false,
		isAuthenticated: false
	},
	{
		path: '/public/recover/*',
		component: RecoverPassword,
		viewHeader: false,
		isAuthenticated: false
	},
	{
		path: '/error403',
		component: Error403,
		viewHeader: false,
		isAuthenticated: true
	},
	{
		path: '/error404',
		component: Error404,
		viewHeader: false,
		isAuthenticated: true
	},
	{
		path: '/error500',
		component: Error500,
		viewHeader: false,
		isAuthenticated: true
	}
]

