import { useContext, useEffect, useState } from "react";
import ButtonSG from "../../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../../components/design/Table/TableSG";
import { BuyInternalStockModal } from "../../../ecommerce/products/modal/BuyInternalStockModal";
import { MassiveAdjudicationModal } from "../../../ecommerce/products/modal/MassiveAdjudicationModal";
import { InternalStock } from "../../../../../interfaces/InternalStock";
import { InternalArticle } from "../../../../../interfaces/InternalArticle";
import { Tag, Input, Badge, Card } from "antd";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import "./AdjudicateDetail.css"
import { authRequest } from "../../../../../common/request/authRequest";
import { FilterDropdownProps } from "../../../../../types/filterDropDown";
import { CaretRightOutlined, CheckOutlined, DoubleRightOutlined, SearchOutlined, ShopOutlined, ShoppingCartOutlined, SwapOutlined } from '@ant-design/icons';

import { ADJUDICATION_STATUS } from "../../../../../common/constants/adjudicationStatus";
import { formatPrice } from "../../../../../common/utils/formatPrice";
import { AssignArticleModal } from "../../../ecommerce/products/modal/AssignArticleModal";

export const CardMetalAdjudication = ({ metalAdjudication, investmentId, updateData, setUpdateData, contractId, clientId, clientName }: any) => {

    const [internalStock, setInternalStock] = useState<InternalStock | null>(null);
    const [price, setPrice] = useState<any>(null);
    const [assignArticleModal, setAssignArticleModal] = useState<any | null>(null)
    //const [massiveAdjudication, setMassiveAdjudication] = useState<InternalArticle[] | null>(null)

    const { setArticleDrawer, setProductDrawer } = useContext(DrawerContext)

    const columns: any = [
        {
            title: 'Producto',
            key: 'productName',
            dataIndex: 'productName',
            render: (_: any, record: any) => {
                return <a onClick={() => setProductDrawer(record.sku)}>
                    <div>{record.productName}</div>
                    <small>{record.sku}</small>
                </a>
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Nombre"
                        value={selectedKeys[0]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: string, record: any) => {
                return (record?.productName.toLowerCase().includes(value.toLowerCase())
                    || (record?.sku.toLowerCase().includes(value.toLowerCase())));
            },
        },            
        {
            title: 'Precio',
            key: 'price',
            dataIndex: 'price',
            align: 'center',
            render: (text: string, record: any) => (
                <Tag color="var(--white)" className='market_price'>{formatPrice(record.price, 2)} €</Tag>
            ),
            sorter: (a: any, b: any) => b.price - a.price

        },            
        {
            title: 'Comisión',
            key: 'margin',
            dataIndex: 'margin',
            align: 'center',
            render: (text: string, record: any) => (
                <>{record.margin && <>{formatPrice(100 * record.margin, 1)} %</>}</>
            )

        },       
        {
            title: 'Código de Barras',
            key: 'barCode',
            dataIndex: 'barCode',
            align: 'center',
            render: (text: string, record: any) => (                
                <>                    
                    <a onClick={() => setArticleDrawer(record.article.id)}>
                        <strong>{record.article?.barCode}</strong>
                    </a>
                </>                
            ),
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Código de barras"
                        value={selectedKeys[0]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: string, record: any) => {
                return (record?.article?.barCode?.toLowerCase().includes(value.toLowerCase()));
            },
        },
        {
            title: 'Tipo',
            key: 'type',
            align: 'center',

            render: (text: string, record: any) => (
                <>
                    {
                        record?.type && <Tag color={ADJUDICATION_STATUS[record?.type].color}>{ADJUDICATION_STATUS[record?.type].name}</Tag>
                    }                    
                </>
            )
        },
        {
            title: '',
            key: 'action',
            align: 'center',

            render: (text: string, record: any) => (
                <>                    
                    {
                        record.type === 'ASSIGNED' && <CheckOutlined/>
                    }
                    {
                        record.type !== 'ASSIGNED' && (record.article?.barCode
                            ?
                            <ButtonSG size="small" key="submit" type="primary" color="yellow" onClick={() => { setPrice(record.price * (1 + record.margin)); setAssignArticleModal(record.article);  }}>
                                <DoubleRightOutlined/> Comprar
                            </ButtonSG>
                            :
                            <ButtonSG size="small" key="submit" type="primary" color="yellow" onClick={() => { setInternalStock(record) }}>
                                <ShoppingCartOutlined/> Aprovisionar
                            </ButtonSG>)
                    }
                </>
            )
        }
    ]

    return (
        <>
            <div>
                <Card style={{ border: 'none' }}>
                    {
                        <>                            
                            <div style={{ padding: '30px 10px' }}>
                                <h1><CaretRightOutlined/>Propuesta Adjudicación de <b>{metalAdjudication?.globalInfo?.metalName}</b></h1>
                                <Card bordered={false}>
                                    Adjudicado: <span><Tag color="lightblue" className='market_price'>{metalAdjudication.globalInfo?.investedRevenue} €</Tag></span>
                                    Excedente: <span><Tag color='var(--white)' className='market_price'>{metalAdjudication.globalInfo?.remainingRevenue} €</Tag></span>                                    
                                </Card>
                                <div>
                                    {/*<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <ButtonSG
                                            //disabled={!allProductsHasArticles} 

                                            size="small" key="submit" type="primary"
                                        //onClick={() => setMassiveAdjudication(adj.productsByMetal)}

                                        > Asignación masiva </ButtonSG>
                                    </div>*/}
                                    <TableSG
                                        columns={columns}
                                        dataSource={metalAdjudication.productsByMetal}
                                        size="middle"
                                        loading={false}
                                        rowKey="article.id"
                                    />
                                </div>
                            </div>
                        </>
                        
                    } </Card>                    
            </div>
            {
                internalStock &&
                <BuyInternalStockModal
                    updateData={updateData} setUpdateData={setUpdateData}
                    internalStock={internalStock}
                    onFinish={() => { setInternalStock(null); }} />
            }            
            {                
                assignArticleModal &&
                <AssignArticleModal investmentId={investmentId} constractId={contractId}  clientId={clientId}  clientName={clientName} defaultPrice={price} articleInfo={assignArticleModal} updateData={updateData} setUpdateData={setUpdateData} onFinish={() => { setAssignArticleModal(null); }} />
            }
            {/*
                massiveAdjudication &&
                <MassiveAdjudicationModal investmentId={investmentId} articleInfo={massiveAdjudication} updateData={updateData} setUpdateData={setUpdateData} onFinish={() => { setMassiveAdjudication(null) }} />
        */}
        </>
    )
}
