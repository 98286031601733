import { Alert, Badge, Button, Card, Modal, Radio, RadioChangeEvent, Spin, Switch, Tag, Tooltip } from "antd";
import TableSG from "../../../components/design/Table/TableSG";
import { useContext, useState, useEffect } from "react";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { Link } from "react-router-dom";
import { BarChartOutlined, CaretDownOutlined, CaretUpOutlined, CheckCircleFilled, CheckCircleOutlined, CheckCircleTwoTone, CheckOutlined, ClockCircleFilled, ClockCircleOutlined, DoubleRightOutlined, EuroCircleOutlined, EuroOutlined, ForwardOutlined, GoldOutlined, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { formatPrice } from "../../../../common/utils/formatPrice";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import { BalanceChart } from "./modal/BalanceChart";
import { Column } from "@ant-design/charts";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { customRequest } from "../../../../common/request/customRequest";
import moment from "moment";
import { formatPercentage } from "../../../../common/utils/FormatUtils";
import { ConfirmOperation } from "./modal/ConfirmOperation";
import { INVESTMENT_TYPE } from "../../../../common/constants/investmentType";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import { ROLE_ADMIN, ROLE_OPERATIONS } from "../../../../common/constants/roles";
import { AdjustmentModal } from "./modal/AdjustmentModal";

export const TotalFreedom = () => {

    const [seeActiveContracts, setContractState] = useState(true);
    const [uploadPrice, setUploadPrice] = useState(false);
    const [updateData, setUpdateData] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { setContractDrawer } = useContext(DrawerContext)
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [operationModalData, setOperationModalData] = useState<any>(null);


    const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/contracts/type/TOTAL_FREEDOM?active=${seeActiveContracts}`, {}, [updateData]);
    //const { data: kpi, } = useFetch(`${process.env.REACT_APP_API}/operations/freedom/kpis`, {}, []);

    const automaticOperation = async (id: string, value: boolean) => {

        await customRequest(`${process.env.REACT_APP_API}/contracts/${id}/?isAutomated=${value}`, {
            method: 'PATCH',
        }).then(() => setUpdateData(!updateData))
    }

    const columns = [
        {/*
            title: <>{`${selectedRowKeys.length} de ${data?.length}`}</>,
            render: (_: any, record: any) => {
                return <Switch size="small" checked={record.automated} onChange={(value) => { automaticOperation(record.id, value) }} />
            }
        */},
        {
            title: 'Contrato',
            dataIndex: 'automated',
            key: 'automated',
            width: '16%',
            //align: 'center',
            render: (_: any, record: any) => (
                    <Badge.Ribbon text={record.automated ? "Automático" : "Manual"} color={record.automated ? "var(--primary)" : "var(--accent-variant)"}  >
                    <Card style={{ padding: '5px 0px 0px 0px', backgroundColor: 'white', borderRadius: '10px'}}>                        
                        <a onClick={() => setContractDrawer(record.id)}>
                            <EntityLabel style={{ display: 'inline' }} prefix='Contrato' id={record.id} />
                        </a>
                        <small>{record.clientName}</small>
                    </Card>
                </Badge.Ribbon>
            )

        },
        {
            title: 'Inversión',
            dataIndex: 'revenue',
            align: 'center',
            render: (_: any, record: any) => (

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <PriceTag price={record.revenue} />                    
                </div>
            ),
            sorter: (a: any, b: any) => a.revenue - b.revenue,
        },
        {
            title: 'Saldo',
            dataIndex: 'cashAccount',
            render: (_: any, record: any) => (
                <>
                    <div><EuroCircleOutlined /> {formatPrice(record.cashAccount)}€</div>
                    <div>
                        <Tooltip title={`${record.units} lingotes`}>
                            <GoldOutlined /> {formatPrice(record.unitsCostAccount)}€
                        </Tooltip>
                    </div>                    
                </>
            ),
        },
        {
            title: 'Saldo Total',
            dataIndex: 'benefit',
            align: 'center',
            render: (_: any, record: any) => (
                <b>
                    <div>{formatPrice(record.cashAccount + record.unitsCostAccount)}€</div>
                    {record.benefit >= 0 ?
                        <small style={{ color: 'green', marginRight: '8px' }}><CaretUpOutlined />{formatPrice(record.benefit)}%</small>
                        :
                        <small style={{ color: 'red', marginRight: '8px' }}><CaretDownOutlined />{formatPrice(record.benefit)}%</small>
                    }
                </b>                
            ),

        },  
        {/*
            title: 'Margen',
            dataIndex: 'silverGoldMargin',
            align: 'center',
            render: (_: any, record: any) => (
                <>{formatPrice(record.marginSG)}€</>
            ),
            */},              
        {
            title:  'De Jueves a ayer',
            align: 'center',
            width: '15%',
            render: (_: any, record: any) => {

                return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>                    
                    {record?.operation &&
                        Object.entries(record.operation).map(([key, value]: any) => (
                            <> 
                                {"PENDING_OPERATION" !== value.status &&
                                value && "NO_MARKET_DATA" !== value.status && (  value.increment >= 0 ?  
                                    <small  style={{ color: 'green', marginRight: '8px' }} key={key}>
                                        {`${value.metalName} ${value.increment.toFixed(2)}%`}<CaretUpOutlined />
                                    </small>   
                                    :
                                    <small  style={{ color: 'red', marginRight: '8px' }} key={key}>
                                        {`${value.metalName} ${value.increment.toFixed(2)}%`}<CaretDownOutlined />
                                    </small>                                         
                                )}
                                {"NO_MARKET_DATA" === value.status && <Alert showIcon type="warning" message={<span>Sin info</span>} style={{ padding: '1px 15px', borderRadius: '8px', width: 'fit-content', margin: 'auto' }}></Alert>}
                            </>
                    ))}                     
                </div>
            },
        },
        {
            title: 'Sugerencia',
            align: 'center',
            render: (_: any, record: any) => {

                return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {record.operation &&
                        Object.entries(record.operation).map(([key, value]: any) => (
                            <>          
                                {value.readyToOperate ?                      
                                    "NO_MARKET_DATA" !== value.status && 
                                    <>
                                        <div style={{ display: 'flex', gap: '5px' }} >
                                            <ButtonSG   size="small" disabled={record.automated || !value.readyToOperate} 
                                                        onClick={() => setOperationModalData({ contract: record, metalInfo: value })}
                                                        style={{marginTop:'4px'}}>
                                                <ClockCircleOutlined/>
                                                {"BUY" === value.status && <>Comprar {formatPercentage(value.buyPercentage, 2)}% de {value.metalName}</>}
                                                {"SELL" === value.status && <>Vender {value.sellUnits} uds. de {value.metalName}</>}
                                                {value.status.includes("NO_OPERATION") && <>No accionar {value.metalName}</>}
                                            </ButtonSG>
                                        </div>
                                        {value.lastOperation && <small style={{color:'grey'}}><i>{moment(value.lastOperation).format('ll')}</i></small>}
                                    </>                    
                                :
                                <>       
                                    <ButtonSG  color="dark-white" size="small" disabled={true} style={{marginTop:'14px'}}>
                                        {"PENDING_OPERATION" === value.status ? 
                                            <p><LoadingOutlined spin/> {value?.metalName}: {INVESTMENT_TYPE[value.pendingOperation]?.name} pendiente</p>
                                            :
                                            <><CheckOutlined/> {value.metalName}</>
                                        }
                                    </ButtonSG>                          
                                    {value.lastOperation &&<small style={{color:'grey'}}><i>{moment(value.lastOperation).format('ll')}</i></small>}
                                </>
                            }                        
                        </>
                    ))}                    
                </div>
            },
        },
        {
            title: '',
            align: 'center',
            render: (_: any, record: any) => {

                return <a onClick={() => {
                    setSelectedRecord(record)
                }} style={{ color: 'var(--accent-variant)' }}><BarChartOutlined className='menu2-icon'/></a>
            },
        }
    ]
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record: any) => ({
            disabled: record.automated,
        })
    };

    return (
        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
            <div className="table-header">
                <span className='title-summary'>Contratos Libertad Total</span>                
            </div>
            <br/>
            
            {/* <div style={{ display: 'flex', gap: '30px', justifyContent: 'center', padding: '20px' }}>

                <Card size='small' style={{ borderRadius: '25px', display: 'flex', width: '35%', background:'WhiteSmoke' }}>
                    <div >
                        <h2 style={{ margin: 0 }}>Inversión</h2>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                        <div><b>Cantidad: </b> <span>{kpi?.balanceKpis?.totalRevenue} €</span></div>
                        {kpi?.balanceKpis?.benefit >= 0 ?
                            <div style={{ color: 'green' }}><b style={{ color: 'black' }}>Beneficio Cliente: </b> <span>{kpi?.balanceKpis?.benefit}%</span></div>
                            :
                            <div style={{ color: 'red' }}><b style={{ color: 'black' }}>Beneficio Cliente: </b> <span>{kpi?.balanceKpis?.benefit}%</span></div>
                        }
                    </div>
                </Card>            
                
                <Card size='small' style={{ borderRadius: '25px', display: 'flex', width: '35%', background:'WhiteSmoke' }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 style={{ margin: 0 }}>Saldo</h2>
                    </div>

                    <div style={{ display: 'flex', gap: '50px', margin: '10px' }}>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><EuroOutlined />{kpi?.balanceKpis?.cashAccount}€</p>
                            <Tooltip placement="leftBottom" title={`${kpi?.balanceKpis?.units} lingotes`}><p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><GoldOutlined />{kpi?.balanceKpis?.unitsCostAccount}€</p></Tooltip>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div ><b>Margen</b> <span>{kpi?.balanceKpis?.marginSG}€</span></div>
                        </div>

                    </div>
                </Card>
                
                <Card size='small' style={{ borderRadius: '25px', display: 'flex', width: '35%', background:'WhiteSmoke' }}>
                    <div >
                        <h2 style={{ margin: 0 }}>Automatización</h2>
                        <small style={{ marginLeft: '10px' }}><b>{moment(kpi?.nextOperationKpi?.nextOperation).format('LL')}</b></small>
                    </div>

                    <div style={{ display: 'flex', gap: '50px', margin: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
                            <p>{kpi?.nextOperationKpi?.automaticContracts} Contratos Automáticos</p>
                            <p>{kpi?.nextOperationKpi?.manualContracts}  Contratos Manuales</p>
                        </div>
                    </div>
                </Card>
            </div>
            <br/> */}

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                 <Radio.Group defaultValue={true} buttonStyle="outline" onChange={(e: RadioChangeEvent) => setContractState(e.target.value)}>
                    <Radio.Button value={true}>Activos</Radio.Button>
                    <Radio.Button value={false}>Liquidados</Radio.Button>
                </Radio.Group>                

                <ButtonSG disabled={!(selectedRowKeys.length > 0)} size="small"
                    onClick={
                        () => {
                            Modal.confirm({
                                title: '¿Confirma la subida de estos precios?',
                                okText: 'Confirmar',
                                cancelText: 'Cancelar',
                                onOk() {
                                    setUploadPrice(true);
                                    //   uploadPrice()
                                }
                            });
                        }
                    }
                >
                    {/*!uploadPrice ? "Confirmar Sugerencia" : <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />*/}
                </ButtonSG>
                 
            </div>
               
            {data && <TableSG columns={columns} dataSource={data} loading={isLoading} rowKey={'id'} />}
            {
                selectedRecord &&

                <Modal
                    open={selectedRecord}
                    onCancel={() => { setSelectedRecord(null) }}
                    footer={null}
                    width='90%'
                    style={{ position: 'absolute', top: 10, left: 5, right: 5 }}
                >
                    <BalanceChart id={selectedRecord.id} name={selectedRecord.clientName}  onFinish={() => { setSelectedRecord(null) }} />
                </Modal>
            }
            {
                operationModalData && <ConfirmOperation 
                onCancel={()=>{setOperationModalData(null)}}
                suggestion={operationModalData} onFinish={() => { setOperationModalData(null); setUpdateData(!updateData) }} />
            }                 
        </Card>
    )
}