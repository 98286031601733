import {
    Card, Tag
} from "antd";
import { Link, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import KpisContract from "./KpisContract";
import { EntityLabel } from "../../../../components/design/EntityLabel/EntityLabel";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import { DoubleLeftOutlined } from "@ant-design/icons";
import { METAL_NAMES } from "../../../../../common/constants/metalNames";
import { GlobalVariableContext } from "../../../../../store/GlobalVariableContext/GlobalVariableContext";
import { CardMetalAdjudication } from "./CardMetalAdjudication";
import { CardMetalSales } from "./CardMetalSales";

export const AdjudicateSale = () => {

    const location = useLocation();
    const pathName = location.pathname.split('/');
    const investmentId = pathName[pathName.length - 1];

    //const {updateAdjucateData} = useContext(GlobalVariableContext);
    const [updateData, setUpdateData] = useState(false);

    const { setContractDrawer } = useContext(DrawerContext)

    const [metalAdjudication, setMetalAdjudication] = useState<any>({});
    const [investmentKPIs, setInvestmentKPIs] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    
    const {data} = useFetch(`${process.env.REACT_APP_API}/investments/${investmentId}/assignations/sale`, {}, [updateData], (data) => {
                
        setMetalAdjudication(data.metalAdjudication);        
        setInvestmentKPIs(data.mainKPIs);        
        setIsLoading(false);        
    });    

    return (
        <Card
            style={{ border: "none" }}
            bodyStyle={{ padding: "0px 10px 10px 10px" }}
        >
            <div className="table-header">
                {/* <span className='title-summary'>Asignar Lote</span>                        */}
                <div style={{display:'flex', alignItems:'center'}}>
                    <Tag color="var(--primary)" style={{fontSize:'17px', borderRadius:'8px'}}>
                        {/* {data.investmentBuy && <> Compra {formatPrice(data?.investmentBuy?.revenue, 2)}€ de {METAL_NAMES[data?.investmentBuy?.metalCode].name}</>} */}
                        {data.investmentSale && <> Venta {data?.investmentSale?.units} uds. de {METAL_NAMES[data?.investmentSale?.metalCode].name}</>}
                    </Tag>
                    <Tag style={{fontSize:'18px', padding:'3px'}}>{data.contractType}</Tag>

                </div>
                <Link to={`/logistica/adjudicar`}>
                    <><b style={{color:'var(--primary)', fontSize:'17px'}}><DoubleLeftOutlined /> Volver</b></>
                </Link>
            </div>                        
            <div style={{margin:'5px 0px 20px 30px'}}>
                <a  onClick={() => setContractDrawer(investmentKPIs.contractId)}>
                    <EntityLabel prefix='Contrato' id={investmentKPIs.contractId} />
                </a>
            </div>

            <div>{ investmentKPIs && <KpisContract kpis={investmentKPIs}  investment={data.investmentSale} id={data.id} type={data.type} /> } </div>
            <div>{data.articleWithStatus && <CardMetalSales articleWithStatus = {data.articleWithStatus} onChange={() => {setUpdateData(!updateData)}}/> }</div>
        </Card>
    );
};
