import { SearchOutlined } from "@ant-design/icons";
import {
    Badge,
    Card,
    Checkbox,
    Input,
    Modal,
    TableColumnsType,
    Tag,
    Tooltip,
} from "antd";
import { useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { ProductByArticle } from "../../../../interfaces/productByArticle";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import TableSG from "../../../components/design/Table/TableSG";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import { FilterDropdownProps } from "antd/lib/table/interface";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { OnSaleModal } from "./OnSaleModal";



export const OnSale = () => {

    const { setProductDrawer, setContractDrawer, setArticleDrawer, setWordPressOrderDrawer } = useContext(DrawerContext)

    const { data: storesData, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/products/articles/?status=${true}`,
        {},
        []
    );

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [openOnSaleModal, setOpenOnSaleModal] = useState(false);

    const onSelectChange = (selectedRowKeys: any) => {
        const selectedItemsData = storesData?.filter((row: any) => selectedRowKeys.includes(row.id))
            .map((row: any) => ({ id: row.id, price: row.price }));

        setSelectedItems(selectedItemsData || []);

        setSelectedRowKeys(selectedRowKeys);
        setIndeterminate(true);
    }
    const [selectedItems, setSelectedItems] = useState<{ id: React.Key, price: number }[]>([]);


    const onSelectAllCustom = (selected: boolean) => {
        const selectedItemsData = selected
            ? storesData?.map((row: any) => ({ id: row.id, price: row.price }))
            : [];

        setSelectedItems(selectedItemsData || []);

        const selectedKeys = selected
            ? storesData?.reduce((keys: any[], row: any) => {
                // if (!(row.suggestedPrice === null || row.priceParams.automaticSync)) {
                //     keys.push(row.id);
                // }
                keys.push(row.id);

                return keys;
            }, [])
            : [];

        setSelectedRowKeys(selectedKeys);
        setIndeterminate(false);
    }


    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnTitle: storesData?.id ?
            <Checkbox disabled={true}></Checkbox>
            :
            <Checkbox indeterminate={indeterminate} onChange={(e) => onSelectAllCustom(e.target.checked)}></Checkbox>,
        getCheckboxProps: (record: any) => ({
            name: record.id,
        }),
    }


    const columns: TableColumnsType<ProductByArticle> = [
        {
            title: "Tipo de Contrato",
            dataIndex: "contractType",
            key: "contractType",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Tipo de Contrato"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                return record.contractType.some((type: string) => type.toLowerCase().includes(value.toLowerCase()));
            },
            render: (_: any, record: any) => (
                <>
                    {record.contractType.map((type: string, index: number) => (
                            <Tag color='var(--primary)' style={{borderRadius:'8px'}} key={index}>
                                <b>{type}</b>
                            </Tag>
                    ))}
                </>
            )
        },
        {
            title: 'Id',
            align: 'center',
            dataIndex: "id",
            key: "id",
            render: (_: any, record: any) => (
                <>
                    {
                        <div style={{ display: 'flex' }}>
                            <a onClick={() => setArticleDrawer(record.id)}>
                                <EntityLabel prefix="Artículo" id={record.id} color={false} />
                            </a>
                        </div>
                    }
                </>
            ),

        },
        {
            title: "Código de Barras",
            align: 'center',
            dataIndex: "barCode",
            key: "barCode",
            render: (_: any, record: any) => (
                <>
                    {
                        <div>
                            {record.barCode}
                        </div>
                    }
                </>
            ),
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Código de barras"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                return record?.barCode?.includes(value);
            },
        },
        {
            title: "Almacén", dataIndex: "store", key: "store", filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Almacén"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                return (record.store && record.store.toLowerCase().includes(value.toLowerCase()));
            },
        },               
        {
            title: 'Coste',
            key: 'cost',
            dataIndex: 'cost',
            align: 'center',
            render: (_: any, record: any) => (
                <>
                    {
                        <div>
                            <PriceTag price={record.cost} />
                        </div>
                    }
                </>
            ),
            sorter: (a: any, b: any) => a.cost - b.cost
        },
        {
            title: "Producto",
            dataIndex: "productTypeId",
            key: "productTypeId",
            render: (_: any, record: any) => (
                <>
                    <div>{record.productType.name}</div>
                    <a onClick={() => {
                        setProductDrawer(record.productType.sku);
                    }}>
                        <small>{record.productType.sku}</small>
                    </a>
                </>
            ),
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Nombre o sku"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {

                return (record.productType.name && record.productType.name.toLowerCase().includes(value.toLowerCase())) ||
                    (record.productType.sku && record.productType.sku.toLowerCase().includes(value.toLowerCase())) ? true : false;
            },
        },
        {
            title: 'Propietario',
            key: 'ownerDescription',
            render: (_: any, record: any) => (
                <>
                    <span>
                        {
                            record.contractId ? (
                                <>
                                    <a onClick={() => setContractDrawer(record.contractId)}>
                                        <EntityLabel style={{ display: 'inline' }} prefix='Contrato' id={record.contractId} />
                                    </a>
                                    <small>{record.clientName}</small>
                                </>
                            ) :
                                record.clientId ?
                                    <>
                                        <a onClick={() => setWordPressOrderDrawer({ id: record.wordPressOrderId, webName: record.wordPressOrderWebName })}>
                                            <EntityLabel style={{ display: 'inline' }} prefix='WordPress' id={record.wordPressOrderId} />
                                        </a>
                                        <small>{record.clientName}</small>
                                    </>
                                    :
                                    record.ownerDescription
                        }
                    </span>
                </>
            )
        }]

    return (
        <Card
            style={{ border: "none" }}
            bodyStyle={{ padding: "0px 10px 10px 10px" }}>
            <div className="table-header">
                <span className='title-summary'>Provisión para contratos</span>
            </div>
            <p className='pl-20 desc'>Configurar en el submenú de almacenes</p>
            {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonSG disabled={!(selectedItems?.length > 0)} size="small" style={{ width: '120px' }}
                    onClick={() => { setOpenOnSaleModal(true) }}
                >
                    Vender
                </ButtonSG>
            </div> */}

            <TableSG
                //rowSelection={rowSelection}
                columns={columns}
                dataSource={storesData}
                loading={isLoading}
                rowKey="id"
            />

            {openOnSaleModal && <OnSaleModal selectedItems={selectedItems} onFinish={() => { setOpenOnSaleModal(false) }} />}

        </Card>
    );
};
